import React, { useCallback, useEffect, useState } from "react";
import { Box, ButtonBase, Typography, makeStyles, Modal } from "@material-ui/core";
import { Call, VideoChat } from "@mui/icons-material";
import toast from "react-hot-toast";

import { RenderContact } from "./RenderContact";
import { SearchInput } from "../SearchInput";
import { CallService } from "../../services/CallService";
import { ContactsService } from "../../services/ContactsService";
import { useCallContext } from "../../reducers/Call/CallProvider";
import { CallActionTypes } from "../../reducers/Call/CallActionsTypes";
import { Console } from "../../debug/Console";
import { TabWrapper } from "../TabWrapper";
import { useIsOpen } from "src/hooks";
import VideoRecorder from "../VideoRecorder";
import axios from "axios";
import { AuthService } from "src/services/AuthService";
import { FoxtrotService } from "src/services/FoxtrotService";

export const TabContacts = () => {
  const { callState, dispatchCall, handleAccept, endCall, openSetReasonModal } = useCallContext();
  const { isOpen, close, open } = useIsOpen();
  const [page, setPage] = useState(1);
  const [searchable, setSearchable] = useState("");
  const [foundedContact, setFoundedContact] = useState(null);
  const [error, setError] = useState(null);

  const handleSetCall = useCallback(
    async (el) => {
      endCall();

      console.log("el here ", el);
      dispatchCall({
        type: CallActionTypes.PREVIEW_CALL,
        payload: { ...el },
      });
      handleAccept();
      // dispatch(setUserReasonSelected(el.reason));
      // dispatch(setSessionIdToSubmit(el.session_id));
    },
    [dispatchCall, handleAccept, endCall, callState.selectedCall.token]
  );

  const handleClick = async (value) => {
    if (error) {
      setError(null);
    }
    const cleanedValue = value.replace(/se1/gi, "").trim();

    const processedResponse = async (data) => {
      // console.log("responce contact data", data);
      try {
        if (!data || !data.success) {
          toast(data.message);
  
          return;
        }

        const deafId = await FoxtrotService.getFoxtrotDeafId(data.id).then((d) => {
          if (d.success) {
            return d.deaf_id;
          } else {
            return null;
          }
        });
        setFoundedContact({ ...data, deafId });
      } catch (error) {
        console.error(error);
      }
    };
    // console.log("value", value);
    // const internationalPhoneRegex = /^\+\d{1,4}\d{6,14}$/;

    const defaultCountryCode = "+38";
    const phoneRegex = /^\d{9,14}$/;

    const hasCountryCode = cleanedValue.startsWith(defaultCountryCode);

    const isValidPhoneNumber = phoneRegex.test(cleanedValue) || hasCountryCode;

    // const isValidPhoneNumber = internationalPhoneRegex.test(cleanedValue);
    // console.log("isValidPhoneNumber", isValidPhoneNumber);
    if (isValidPhoneNumber) {
      const phoneNumber = hasCountryCode ? cleanedValue : defaultCountryCode + cleanedValue;
      ContactsService.getContactByPhoneNumber(phoneNumber.trim()).then(processedResponse);
    } else {
      ContactsService.getContactByDeafId(cleanedValue.trim()).then(processedResponse);
    }
  };

  const handlemakeCall = (id) => {
    CallService.makeCall(id).then((data) => {
      //   handleSetCall({...call,data});

      if (!data.success) {
        toast.error(data.message);
        return;
      }
      handleSetCall(data);
      console.log("make call", data);
    });
  };

  const handleSubmitVideo = async (form, setProgressBar) => {
    return await ContactsService.sendVideoMessage({
      clientId: foundedContact.id,
      data: form,
      setProgress: setProgressBar,
    })
      .then((d) => {
        if (d.success) {
          toast.success("Відеозвернення відправлено");
          setProgressBar(0);
          close();
        } else {
          toast.error(d.data.message);
        }
        return d;
      })
      .catch((e) => {
        console.log(e);
        toast.error("Не вдалося відправити відео");
      });
  };

  return (
    <>
      <SearchInput handleClick={handleClick} />
      {/* {error ? <div>{error.message}</div> : null} */}
      <Box style={{ display: "flex", padding: "12px 18px" }}>
        {foundedContact ? (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              flexGrow: 1,
              padding: 6,
              borderRadius: 12,
            }}
          >
            <Typography>{foundedContact.name}</Typography>
            {Boolean(foundedContact.deafId) && (
              <Typography>Deaf ID: {foundedContact.deafId}</Typography>
            )}
            <Typography>ID: {foundedContact.id}</Typography>

            <Box sx={{ gap: 8, display: "flex" }}>
              <ButtonBase
                style={{ gap: 4 }}
                onClick={() => {
                  handlemakeCall(foundedContact.id);
                }}
              >
                <Call sx={{ width: 36, height: 36 }} />
                {/* <Typography>Подзвонити</Typography> */}
              </ButtonBase>
              <ButtonBase style={{ gap: 4 }} onClick={open}>
                <VideoChat sx={{ width: 36, height: 36 }} />
                {/* <Typography variant="caption">Відеоповідомлення</Typography> */}
              </ButtonBase>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Modal
        children={
          <>
            <VideoRecorder close={close} videoFormatForSubmit="mp4" onSubmit={handleSubmitVideo} />
          </>
        }
        open={isOpen}
        onClose={close}
      />
      {/* <RenderContact /> */}

      {/* {callState.nextPage && (
				<ButtonBase
					onClick={() =>
						!!searchable.length
							? getFindContact()
							: getAllContacts()
					}
					style={{
						width: '100%',
						bottom: 0,
						height: 50,
						backgroundColor: '#00000030',
					}}
				>
					Завантажити ще
				</ButtonBase>
			)} */}
    </>
  );
};
